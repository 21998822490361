import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
    network$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  constructor() { }

    setValue(value: number) {
      this.network$.next(value);
    }

    getValue() {
      return this.network$.value;
    }
}
