import { ChangeDetectorRef, computed, DestroyRef, Directive, ElementRef, signal, } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NetworkService } from 'app/core/services/helper/network.service';

@Directive({
    selector: '[buttonLoading]',
    standalone: true,
    host: {
        '[attr.disabled]': 'disabled()',
    }
})
export class ButtonLoadingDirective {
    isLoading = signal(false);
    isClicked = signal(false);

    disabled = computed(() => {
        return (this.isLoading() && this.isClicked()) ? true : null
    });
    prev = 0;
    current = 0;
    animation = '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><style>.spinner_P7sC{transform-origin:center;animation:spinner_svv2 .75s infinite linear}@keyframes spinner_svv2{100%{transform:rotate(360deg)}}</style><path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" class="spinner_P7sC"></path></svg>';
    text = '';

    constructor(private networkService: NetworkService,
        private destroyRef: DestroyRef,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef) { }

    ngOnInit() {
        this.networkService.network$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(resp => {
                this.prev = this.current;
                this.current = resp;
                this.isLoading.set(resp > 0);
                if (!this.isLoading()) {
                    this.isClicked.set(false);
                    if (this.text) {
                        this.labelElement.innerHTML = this.text;
                        this.text = '';
                    }
                }
                this.cd.detectChanges();
            });


        this.elementRef.nativeElement.addEventListener('click', (e) => {
            if ((this.isLoading() && this.isClicked())) {
                e.stopImmediatePropagation();
                e.stopPropagation();
            }
            this.isClicked.set(true);
            if (!this.text) {
                this.text = this.labelElement.innerHTML;
            }
            setTimeout(() => {
                if (this.prev < this.current && this.labelElement.innerHTML !== this.animation) {
                    this.labelElement.innerHTML = this.animation;
                }
            }, 1);
        }, true);
    }

    get labelElement() {
        return this.elementRef.nativeElement.getElementsByClassName('dx-button-content')[0];
    }

}
